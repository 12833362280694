import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  getWorkOrder,
  getWorkOrderSuccess,
  getWorkOrderFailure,
  saveDailyUpdate,
  hideLoader,
  getActivityCodeLookup,
  getActivityCodeLookupSuccess,
} from "./DailyUpdateSlice";
import { getRequest, postRequest } from "app/axiosClient";
import { message } from "antd";

function* getWorkOrderAPI(action) {
  let locale =
    localStorage.getItem("selectedlanguage")?.substring(0, 2) ?? "fr";
  try {
    const response = yield call(() =>
      getRequest(
        `api/DailyUpdate/Info/${locale}/${action.payload.id}/${action.payload.date}/${action.payload.greenHouseId}`
      )
    );
    yield put(getWorkOrderSuccess(response.data));
  } catch (e) {
    yield put(getWorkOrderFailure());
    message.warning(
      "Error while fetching the work order. Please try again later.!"
    );
  }
}

function* saveDailyUpdateAPI(action) {
  try {
    const response = yield call(() =>
      postRequest(`api/DailyUpdate/Add`, action.payload)
    );
    yield put(hideLoader());
    message.success("Daily update saved successfully.!");
  } catch (e) {
    yield put(hideLoader());
    message.warning(
      "Error while saving the daily update. Please try again later.!"
    );
  }
}

function* getActivityCodeLookupAPI(action) {
  try {
    let locale =
      localStorage.getItem("selectedlanguage")?.substring(0, 2) ?? "fr";
    var type = `api/Lookup/GetById/${locale}/${action.payload}`;
    const response = yield call(() => getRequest(type));
    yield put(getActivityCodeLookupSuccess(response.data, action.payload));
  } catch (e) {
    yield put(hideLoader());
    message.warning("Data not available");
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(getWorkOrder, getWorkOrderAPI),
    takeLatest(saveDailyUpdate, saveDailyUpdateAPI),
    takeLatest(getActivityCodeLookup, getActivityCodeLookupAPI),
  ]);
}
