import { all, call, put, takeLatest } from "redux-saga/effects";
import { getRequest, deleteRequest, postRequest } from "app/axiosClient";
import { message } from "antd";
import {
  onGetFinalProductStart,
  onGetFinalProductSuccess,
  onGetFinalProductFailure,
  onFinalProductSaveStart,
  onFinalProductUpdateStart,
} from "./FinalProductSlice";

function* GetAllFinalProduct() {
  try {
    const response = yield call(() =>
      getRequest(`api/FinalProductMaster/GetAll`)
    );

    yield put(onGetFinalProductSuccess(response.data));
  } catch (e) {
    yield put(onGetFinalProductFailure());
    message.warning("Data not available");
  }
}

function* SaveFinalProduct(action) {
  try {
    const response = yield call(() =>
      postRequest(`api/FinalProductMaster/Add`, action.payload)
    );
    if (response.data) {
      yield call(() => GetAllFinalProduct());
      message.success("Final Product saved successfully");
    }
  } catch (error) {
    yield put(onGetFinalProductFailure());
    message.warning("Data not available");
  }
}

function* UpdateFinalProduct(action) {
  try {
    const response = yield call(() =>
      postRequest(`api/FinalProductMaster/Update`, action.payload)
    );
    if (response.data) {
      yield call(() => GetAllFinalProduct());
      message.success("Final Product updated successfully");
    }
  } catch (error) {
    yield put(onGetFinalProductFailure());
    message.warning("something went wrong");
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(onGetFinalProductStart, GetAllFinalProduct),
    takeLatest(onFinalProductSaveStart, SaveFinalProduct),
    takeLatest(onFinalProductUpdateStart, UpdateFinalProduct),
  ]);
}
