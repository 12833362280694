import { all, call, put, takeLatest } from "redux-saga/effects";
import { useDispatch } from "react-redux";

import {
  onStart,
  onSuccess,
  onFailure,
  onUpdateQtyToProduce,
  onUpdateQtyToProduceSuccess,
  onWorkOrderByUPCFailure,
  onWorkOrderByUPCSuccess,
  onWorkOrderByUPCstart,
} from "./DashboardSlice";
import { getRequest, postRequest } from "app/axiosClient";
import { message } from "antd";
import moment from "moment";
function* getDashboard(action) {
  try {
    var currentDate = action?.payload || action;
    const response = yield call(() =>
      getRequest(`api/Dashboard/GetAll?currentYear=${currentDate}`)
    );

    yield put(onSuccess(response.data));
  } catch (e) {
    yield put(onFailure());
    message.warning("Data not available");
  }
}

function* updateQtyToProduce(action) {
  try {
    const response = yield call(() =>
      postRequest("api/Dashboard/UpdateQtyToProduce", action.payload)
    );
    message.success("Updated Quantity to produce sucessfully");
    var res = yield call(() => getDashboard(action?.payload?.Year));
    yield put(onUpdateQtyToProduceSuccess(response.data));
  } catch (e) {
    yield put(onFailure());

    message.warning("Internal Error Occured");
  }
}

function* getWordOrderByUPC(action) {
  try {
    var upc = action?.payload.upc;
    var year = action?.payload.year;
    const response = yield call(() =>
      getRequest(
        `api/Dashboard/GetWorkOrderByUPC?upc=${upc}&currentYear=${year}`
      )
    );
    yield put(onWorkOrderByUPCSuccess(response.data));
  } catch (e) {
    yield put(onWorkOrderByUPCFailure());
    message.warning("something went wrong");
  }
}
export default function* rootSaga() {
  yield all([
    takeLatest(onStart, getDashboard),
    takeLatest(onUpdateQtyToProduce, updateQtyToProduce),
    takeLatest(onWorkOrderByUPCstart, getWordOrderByUPC),
  ]);
}
