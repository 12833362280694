import { all, call, put, takeLatest } from "redux-saga/effects";
import { useDispatch } from "react-redux";
import {
  onInventoryDataLoadStart,
  onInventoryDataLoaded,
  onUploadExcelStart,
  onUploadExcelSuccess,
  onUploadExcelFailure,
} from "./InventorySlice";
import { getRequest, postRequest } from "app/axiosClient";
import { message } from "antd";
import moment from "moment";
function* getAllInventory(action) {
  try {
    const response = yield call(() =>
      getRequest(
        `api/Inventory/GetAllInventory?site=${action.payload.site}&serre=${action.payload.serre}&category=${action.payload.category}&codelcm=${action.payload.codelcm}&upc=${action.payload.upc}&variety=${action.payload.variety}&description=${action.payload.description}`
      )
    );
    yield put(onInventoryDataLoaded(response.data));
  } catch (e) {
    message.warning("something went wrong");
  }
}

function* UploadExcelFile(action) {
  try {
    const response = yield call(() =>
      postRequest(
        `api/Attachment/ImportInventoryExcel`,
        action.payload.formData
      )
    );
    if (response?.data) {
      yield put(onUploadExcelSuccess(response.data));
      const res = yield call(() => getAllInventory(action.payload));
      message.success("Excel is Uploaded..!!");
    } else {
      message.warning("Failed in Uploading");
    }
  } catch (e) {
    yield put(onUploadExcelFailure());
    message.warning("Failed in Uploading");
    if (e?.response) {
      message.warning(`${e.response?.data?.value?.data}`, 5);
    } else {
      message.warning("something went wrong");
    }
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(onInventoryDataLoadStart, getAllInventory),
    takeLatest(onUploadExcelStart, UploadExcelFile),
  ]);
}
