import {
  all,
  call,
  fork,
  join,
  put,
  takeLatest,
  actionChannel,
  take,
} from "redux-saga/effects";
import { getRequest, postRequest, deleteRequest } from "app/axiosClient";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import {
  onGreenHouseStart,
  onGreenHouseSuccess,
  onGreenHouseFailure,
  onGetProductionCalenderStart,
  onGetProductionCalenderSuccess,
  onGetProductionCalenderFailure,
  onColorMasterLookupFailure,
  onColorMasterLookupStart,
  onColorMasterLookupSuccess,
  onContainerMasterLookupSuccess,
  onContainerMasterLookupFailuer,
  onContainerMasterLookupStart,
  onProdCalSaveStart,
  onProdCalSaveFailure,
  onProdCalSaveSuccess,
  onFinalProdLookupStart,
  onFinalProdLookupSuccess,
  onDeleteProdCalenStart,
  onDeleteProdCalenSuccess,
  onCreateWorkOrderStart,
  onCreateWorkOrderSuccess,
  onGetWONextNumStart,
  onGetWONextNumSuccuss,
  onGetWONextNumFailure,
  onTeamLeadLookupStart,
  onTeamLeadLookupSuccess,
  onFailure,
  onUploadExcelStart,
  onUploadExcelSuccess,
  onUploadExcelFailure,
} from "./productionCalenderSlice";
import { onStart } from "../WorkOrder/WorkOrderSlice";
import { message } from "antd";
import { act } from "react-dom/test-utils";
import { async } from "q";

function* saveProductionCalender(action) {
  try {
    const response = yield call(() =>
      postRequest("api/ProductionCalendar/Save", action.payload)
    );

    yield put(onProdCalSaveSuccess(response.data));
    message.success("Saved Production Calender sucessfully");
    const res = yield call(() => getProductionCalender(action));
    if (action.payload.hideForWO) {
      yield put(onStart());
    }
  } catch (e) {
    yield put(onProdCalSaveFailure());
    message.warning("Internal Error Occured");
  }
}
function* getGreenHouseLookup(action) {
  try {
    var type = `api/Lookup/GetAllGreenHouseMasterLookup`;
    const response = yield call(() => getRequest(type));
    yield put(onGreenHouseSuccess(response.data));
  } catch (e) {
    yield put(onGreenHouseFailure());
    //message.warning("Data not available");
  }
}
function* getProductionCalender(action) {
  try {
    let locale =
      localStorage.getItem("selectedlanguage")?.substring(0, 2) ?? "fr";
    var url = `api/ProductionCalendar/GetAll/${locale}?year=${action.payload.searchYear}`;

    if (action && action.payload != null) {
      if (action.payload.week != null && action.payload.poCleroux != null) {
        // url = `${url}?weekNumber=${Number(
        //   action.payload.week.week()
        // )}&year=${Number(action.payload.week.year())}&poCleroux=${
        //   action.payload.poCleroux
        // }`;
        url = `${url}&weekNumber=${Number(
          action.payload.week.week()
        )}&poCleroux=${action.payload.poCleroux}`;
      } else if (
        action.payload.week != null &&
        action.payload.poCleroux == null
      ) {
        // url = `${url}?weekNumber=${Number(
        //   action.payload.week.week()
        // )}&year=${Number(action.payload.week.year())}`;
        url = `${url}&weekNumber=${Number(action.payload.week.week())}`;
      } else if (
        action.payload.week == null &&
        action.payload.poCleroux != null
      ) {
        // url = `${url}?poCleroux=${action.payload.poCleroux}`;
        url = `${url}&poCleroux=${action.payload.poCleroux}`;
      }
    }

    const response = yield call(() => getRequest(url));
    yield put(onGetProductionCalenderSuccess(response.data));
  } catch (e) {
    yield put(onGetProductionCalenderFailure());
    message.warning("undable to fetch ProductionCalender");
  }
}

function* getColorMasterLookup(action) {
  try {
    var type = `api/Lookup/GetById/en/${action.payload}`;
    const response = yield call(() => getRequest(type));
    yield put(onColorMasterLookupSuccess(response.data, action.payload));
  } catch (e) {
    yield put(onColorMasterLookupFailure());
    message.warning("Data not available");
  }
}

function* getcontainerMasterLookup(action) {
  try {
    var type = `api/Lookup/GetById/en/${action.payload}`;
    const response = yield call(() => getRequest(type));
    yield put(onContainerMasterLookupSuccess(response.data, action.payload));
  } catch (e) {
    yield put(onContainerMasterLookupFailuer());
    message.warning("Data not available");
  }
}

function* getFinalProductLookup(action) {
  try {
    const response = yield call(() =>
      getRequest("api/Lookup/GetAllFinalProductMastersLookup")
    );
    yield put(onFinalProdLookupSuccess(response.data));
  } catch (e) {
    yield put(onFailure());
  }
}

function* deleteProductionCalender(action) {
  try {
    try {
      // var type = `api/ProductionCalendar/DeleteById/${action.payload?.id}`;
      var input = action.payload.deleteIds;
      const response = yield call(() =>
        postRequest("api/ProductionCalendar/DeleteById", input)
      );

      yield put(onDeleteProdCalenSuccess());
      message.success("Deleted Work Order Succesfully");

      const res = yield call(() => getProductionCalender(action));
    } catch (e) {
      message.warning("Something went wrong..!");
    }
  } catch (error) {
    yield put(onFailure());
  }
}

function* createWorkOrder(action, dispatch) {
  try {
    const response = yield call(() =>
      postRequest("api/ProductionCalendar/CreateWorkOrder", action.payload)
    );
    yield put(onCreateWorkOrderSuccess(response.data));
    yield put(onGetProductionCalenderStart(action?.payload?.searchText));
    message.success("Created work order Succesfully");
  } catch (e) {
    yield put(onFailure());
    message.warning("Error occured while creating work order");
  }
}

function* getNextWONumber(action) {
  var year = parseInt(new Date().getFullYear());
  try {
    const response = yield call(() =>
      getRequest(`api/ProductionCalendar/NextWorkOrderNumber?year=${year}`)
    );
    yield put(onGetWONextNumSuccuss(response.data));
  } catch (e) {
    yield put(onGetWONextNumFailure());
  }
}

function* getTeamLeadLookup(action) {
  try {
    const response = yield call(() => getRequest("api/Lookup/TeamLeads"));
    yield put(onTeamLeadLookupSuccess(response.data));
  } catch (e) {
    yield put(onFailure());
  }
}
function* UploadExcelFile(action) {
  try {
    const response = yield call(() =>
      postRequest(`api/Attachment/ImportExcel`, action?.payload?.formData)
    );
    if (response?.data) {
      yield put(onUploadExcelSuccess(response.data));
      const res = yield call(() => getProductionCalender(action));
      message.success("Excel is Uploaded..!!");
    } else {
      message.warning("Failed in Uploading");
    }
  } catch (e) {
    yield put(onUploadExcelFailure());
    message.warning("Failed in Uploading");
    if (e?.response) {
      message.warning(`${e.response?.data?.value?.data}`, 5);
    } else {
      message.warning("something went wrong");
    }
  }
}
export default function* rootSaga() {
  yield all([
    takeLatest(onGreenHouseStart, getGreenHouseLookup),
    takeLatest(onGetProductionCalenderStart, getProductionCalender),
    takeLatest(onColorMasterLookupStart, getColorMasterLookup),
    takeLatest(onContainerMasterLookupStart, getcontainerMasterLookup),
    takeLatest(onProdCalSaveStart, saveProductionCalender),
    takeLatest(onFinalProdLookupStart, getFinalProductLookup),
    takeLatest(onDeleteProdCalenStart, deleteProductionCalender),
    takeLatest(onCreateWorkOrderStart, createWorkOrder),
    takeLatest(onGetWONextNumStart, getNextWONumber),
    takeLatest(onTeamLeadLookupStart, getTeamLeadLookup),
    takeLatest(onUploadExcelStart, UploadExcelFile),
  ]);
}
