import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  productionMasterList: [],
  fournisseurLookup: [],
  hybrideurLookup: [],
  variétéLookup: [],
  locationLookup: [],
  lookupData: [],
  editInfo: {},
  didAddToCalendar: false,
  didAddToPoductionMaster: false,
  didDeleteRecord: false,
  didEdit: false,
  loader: false,
};

const ProductionMasterSlice = createSlice({
  name: "productionMaster",
  initialState,
  reducers: {
    onStart(state) {
      state.loader = true;
    },
    onSuccess(state, action) {
      state.loader = false;
      state.productionMasterList = action.payload;
    },
    onFailure(state) {
      state.loader = false;
    },
    onGetAllStart(state) {
      // state.loader = true;
    },
    onGetFournisseurStart(state) {
      //   state.loader = true;
    },
    onGetLocationStart(state) {
      //   state.loader = true;
    },
    onGetVarietyStart(state) {
      //   state.loader = true;
    },
    onGetHybridizerStart(state) {
      //   state.loader = true;
    },
    onGetAllSuccess(state, action) {
      state.lookupData = action.payload;
      //state.loader = false;
    },
    onGetHybridizerSuccess(state, action) {
      state.hybrideurLookup = action.payload;
      //state.loader = false;
    },
    onGetVarietySuccess(state, action) {
      state.variétéLookup = action.payload;
      //state.loader = false;
    },
    onGetLocationSuccess(state, action) {
      state.locationLookup = action.payload;
      //state.loader = false;
    },
    onGetFournisseurSuccess(state, action) {
      state.fournisseurLookup = action.payload;
      //state.loader = false;
    },
    onGetAllFailure(state) {
      //   state.loader = false;
    },

    onAddStart(state) {
      state.loader = true;
      state.didAddToPoductionMaster = false;
    },
    onAddSuccess(state, action) {
      state.loader = false;
      state.didAddToPoductionMaster = true;
      state.editInfo = {};
    },
    onAddFailure(state) {
      state.loader = false;
      state.editInfo = {};
      state.didAddToPoductionMaster = false;
    },

    onDeleteStart(state) {
      state.loader = true;
      state.didDeleteRecord = false;
    },
    onDeleteSuccess(state, action) {
      state.loader = false;
      state.didDeleteRecord = true;
    },
    onDeleteFailure(state) {
      state.loader = false;
      state.didDeleteRecord = false;
    },

    onGetByIdStart(state) {
      state.editInfo = {};
      state.loader = true;
    },
    onGetByIdSuccess(state, action) {
      state.editInfo = action.payload;
      state.loader = false;
    },
    onGetByIdFailure(state) {
      state.editInfo = {};
      state.loader = false;
    },

    onAddToCalendarStart(state) {
      state.loader = true;
      state.didAddToCalendar = false;
    },
    onAddToCalendarSuccess(state, action) {
      state.loader = false;
      state.didAddToCalendar = action.payload;
    },
    onAddToCalendarFailure(state) {
      state.loader = false;
      state.didAddToCalendar = false;
    },

    onReset(state) {
      state.editInfo = {};
    },
  },
});

export const {
  onStart,
  onSuccess,
  onFailure,
  onGetHybridizerStart,
  onGetVarietyStart,
  onGetLocationStart,
  onGetFournisseurStart,
  onGetAllStart,
  onGetAllSuccess,
  onGetHybridizerSuccess,
  onGetVarietySuccess,
  onGetLocationSuccess,
  onGetFournisseurSuccess,
  onGetAllFailure,
  onAddStart,
  onAddSuccess,
  onAddFailure,
  onDeleteStart,
  onDeleteSuccess,
  onDeleteFailure,
  onGetByIdStart,
  onGetByIdSuccess,
  onGetByIdFailure,
  onAddToCalendarStart,
  onAddToCalendarSuccess,
  onAddToCalendarFailure,
  onReset,
} = ProductionMasterSlice.actions;

export default ProductionMasterSlice.reducer;
