import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  workorderlist: [],
  loader: false,
  didDeleteRecord: false,
  statusLookupData: [],
  teamLeadLookup: [],
  workOrderLookup : [],
  didEditRecord: false,
};

const WorkOrderSlice = createSlice({
  name: "workOrder",
  initialState,
  reducers: {
    onStart(state) {
      state.loader = true;
    },
    onSuccess(state, action) {
      state.loader = false;

      for (var j = 0; j < action.payload.length; j++) {
        var element = action.payload[j];
        action.payload[j] = { ...element, key: j };
      }
      state.workorderlist = action.payload;
    },
    onFailure(state) {
      state.loader = false;
    },

    onDeleteStart(state) {
      state.loader = true;
      state.didDeleteRecord = false;
    },
    onDeleteSuccess(state, action) {
      state.loader = false;
      state.didDeleteRecord = true;
    },
    onDeleteFailure(state) {
      state.loader = false;
      state.didDeleteRecord = false;
    },

    onGetAllStatusStart(state) {},
    onGetAllStatusSuccess(state, action) {
      state.statusLookupData = action.payload;
    },
    onGetAllStatusFailure(state) {
      state.statusLookupData = [];
    },

    onTeamLeadLookupStart(state) {
      state.loader = true;
    },
    onTeamLeadLookupSuccess(state, action) {
      state.loader = false;
      state.teamLeadLookup = action.payload;
    },

    onEditStart(state) {
      state.didEditRecord = false;
      state.loader = true;
    },
    onEditSuccess(state, action) {
      state.didEditRecord = true;
      state.loader = false;
    },
    onWorkOrderLookupStart(state , action){
      state.loader = true
    },
    onWorkOrderLookupSuccess(state , action){
      state.loader = false
      state.workOrderLookup = action.payload;

    },
    onUnlinkProdCalForWorkOrderStart(state,action){
      state.loader = true;
    },
    onUnlinkProdCalForWorkOrderSuccess(state,action){
      state.loader = false;
    }
  },
});

export const {
  onStart,
  onSuccess,
  onFailure,
  onDeleteStart,
  onDeleteSuccess,
  onDeleteFailure,
  onGetAllStatusStart,
  onGetAllStatusSuccess,
  onGetAllStatusFailure,
  onTeamLeadLookupStart,
  onTeamLeadLookupSuccess,
  onEditStart,
  onEditSuccess,
  onWorkOrderLookupStart,
  onWorkOrderLookupSuccess,
  onUnlinkProdCalForWorkOrderStart,
  onUnlinkProdCalForWorkOrderSuccess
} = WorkOrderSlice.actions;

export default WorkOrderSlice.reducer;
