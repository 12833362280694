import { all, call, put, takeLatest } from "redux-saga/effects";
import wrapper from "../../components/Wrapper";
import {
  onStart,
  onSuccess,
  onFailure,
  onGetAllFailure,
  onAddStart,
  onAddSuccess,
  onAddFailure,
  onGetHybridizerSuccess,
  onGetVarietySuccess,
  onGetLocationSuccess,
  onGetFournisseurSuccess,
  onGetHybridizerStart,
  onGetVarietyStart,
  onGetLocationStart,
  onGetFournisseurStart,
  onDeleteStart,
  onDeleteSuccess,
  onDeleteFailure,
  onGetByIdStart,
  onGetByIdSuccess,
  onGetByIdFailure,
  onAddToCalendarStart,
  onAddToCalendarSuccess,
  onAddToCalendarFailure,
} from "./ProductionMasterSlice";
import { getRequest, postRequest, deleteRequest } from "app/axiosClient";
import { message } from "antd";
//let locale = JSON.parse(localStorage.getItem("selectedlanguage"));
function* getProductionMaster(action) {
  try {
    let locale =
      localStorage.getItem("selectedlanguage")?.substring(0, 2) ?? "fr";
    var url = `api/ProductionMaster/GetAll/${locale}`;

    if (action && action.payload != null) {
      if (action.payload.week != null && action.payload.poCleroux != null) {
        url = `${url}?weekNumber=${Number(
          action.payload.week.week()
        )}&year=${Number(action.payload.week.year())}&poCleroux=${
          action.payload.poCleroux
        }`;
      } else if (
        action.payload.week != null &&
        action.payload.poCleroux == null
      ) {
        url = `${url}?weekNumber=${Number(
          action.payload.week.week()
        )}&year=${Number(action.payload.week.year())}`;
      } else if (
        action.payload.week == null &&
        action.payload.poCleroux != null
      ) {
        url = `${url}?poCleroux=${action.payload.poCleroux}`;
      }
    }

    const response = yield call(() => getRequest(url));
    yield put(onSuccess(response.data));
  } catch (e) {
    yield put(onFailure());
    message.warning("Data not available");
  }
}

function* getProductionMasterById(action) {
  try {
    const response = yield call(() =>
      getRequest(`api/ProductionMaster/GetById/${action.payload}`)
    );
    yield put(onGetByIdSuccess(response.data));
  } catch (e) {
    yield put(onGetByIdFailure());
    message.warning("Record unavailable");
  }
}

function* getFournisseurLookup(action) {
  try {
    let locale =
      localStorage.getItem("selectedlanguage")?.substring(0, 2) ?? "fr";
    var type = `api/Lookup/GetById/${locale}/${action.payload}`;
    const response = yield call(() => getRequest(type));

    yield put(onGetFournisseurSuccess(response.data, action.payload));
  } catch (e) {
    yield put(onGetAllFailure());
    message.warning("Data not available");
  }
}

function* getHybridizerLookup(action) {
  try {
    let locale =
      localStorage.getItem("selectedlanguage")?.substring(0, 2) ?? "fr";
    var type = `api/Lookup/GetById/${locale}/${action.payload}`;
    const response = yield call(() => getRequest(type));
    yield put(onGetHybridizerSuccess(response.data, action.payload));
  } catch (e) {
    yield put(onGetAllFailure());
    message.warning("Data not available");
  }
}

function* getVarietyLookup(action) {
  try {
    let locale =
      localStorage.getItem("selectedlanguage")?.substring(0, 2) ?? "fr";
    var type = `api/Lookup/GetById/${locale}/${action.payload}`;
    const response = yield call(() => getRequest(type));
    yield put(onGetVarietySuccess(response.data, action.payload));
  } catch (e) {
    yield put(onGetAllFailure());
    message.warning("Data not available");
  }
}

function* getLocationLookup(action) {
  try {
    let locale =
      localStorage.getItem("selectedlanguage")?.substring(0, 2) ?? "fr";
    var type = `api/Lookup/GetById/${locale}/${action.payload}`;
    const response = yield call(() => getRequest(type));

    yield put(onGetLocationSuccess(response.data, action.payload));
  } catch (e) {
    yield put(onGetAllFailure());
    message.warning("Data not available");
  }
}

function* addToProductionMaster(action) {
  try {
    const response = yield call(() =>
      postRequest("api/ProductionMaster/Save", action.payload)
    );
    yield put(onAddSuccess(response.data));
    message.success("Saved successfully");
  } catch (e) {
    yield put(onAddFailure());
    message.warning("Save failed");
  }
}

function* deleteFromProductionMaster(action) {
  try {
    const response = yield call(() =>
      deleteRequest(`api/ProductionMaster/DeleteById/${action.payload}`)
    );
    yield put(onDeleteSuccess(response.data));
    message.success("Deleted successfully");
  } catch (e) {
    yield put(onDeleteFailure());
    message.warning("Delete failed");
  }
}

function* addtoProductionCalendar(action) {
  try {
    const response = yield call(() =>
      postRequest(
        "api/ProductionMaster/AddtoProductionCalendar",
        action.payload
      )
    );
    yield put(onAddToCalendarSuccess(response.data));
    message.success("Added to calendar successfully");
  } catch (e) {
    yield put(onAddToCalendarFailure());
    message.warning("Add to calendar failed");
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(onStart, getProductionMaster),
    takeLatest(onGetFournisseurStart, getFournisseurLookup),
    takeLatest(onGetHybridizerStart, getHybridizerLookup),
    takeLatest(onGetVarietyStart, getVarietyLookup),
    takeLatest(onGetLocationStart, getLocationLookup),
    takeLatest(onAddStart, addToProductionMaster),
    takeLatest(onDeleteStart, deleteFromProductionMaster),
    takeLatest(onGetByIdStart, getProductionMasterById),
    takeLatest(onAddToCalendarStart, addtoProductionCalendar),
  ]);
}
