import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  loader: false,
  finalProductList: [],
};

const FinalProductSlice = createSlice({
  name: "finalProduct",
  initialState,
  reducers: {
    onGetFinalProductStart(state) {
      state.loader = true;
    },
    onGetFinalProductSuccess(state, action) {
      state.loader = false;
      state.finalProductList = action.payload;
    },
    onGetFinalProductFailure(state) {
      state.loader = false;
    },
    onFinalProductSaveStart(state) {
      state.loader = false;
    },
    onFinalProductUpdateStart(state) {
      state.loader = false;
    },
  },
});

export const {
  onGetFinalProductStart,
  onGetFinalProductSuccess,
  onGetFinalProductFailure,
  onFinalProductSaveStart,
  onFinalProductUpdateStart,
} = FinalProductSlice.actions;

export default FinalProductSlice.reducer;
