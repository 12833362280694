import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  ReportsList: [],
  loader: false,
};

const ReportsSlice = createSlice({
  name: "reports",
  initialState,
  reducers: {
    onStart(state) {
      state.loader = true;
    },
    onSuccess(state, action) {
      state.loader = false;
      state.ReportsList = action.payload;
    },
    onFailure(state) {
      state.loader = false;
    },
  },
});

export const { onStart, onSuccess, onFailure } = ReportsSlice.actions;

export default ReportsSlice.reducer;
