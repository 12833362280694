import { all, call, put, takeLatest } from "redux-saga/effects";
import { getRequest, deleteRequest, postRequest } from "app/axiosClient";
import { message } from "antd";
import {
  onSuccess,
  onFailure,
  onStart,
  onDeleteStart,
  onDeleteSuccess,
  onDeleteFailure,
  onGetAllStatusStart,
  onGetAllStatusSuccess,
  onGetAllStatusFailure,
  onTeamLeadLookupStart,
  onTeamLeadLookupSuccess,
  onEditStart,
  onEditSuccess,
  onWorkOrderLookupStart,
  onWorkOrderLookupSuccess,
  onUnlinkProdCalForWorkOrderStart,
  
} from "features/WorkOrder/WorkOrderSlice";
import { isTeamLead } from "../OnBoarding/Authorizaton";
import { onProdCalSaveFailure } from "features/ProductionCalendar/productionCalenderSlice";

function* WorkorderAPI(action) {
  try {
    let locale =
      localStorage.getItem("selectedlanguage")?.substring(0, 2) ?? "fr";
    var url = `api/WorkOrder/GetAllWorkOrders/${locale}?year=${action.payload.searchYear}`;
    if (action.payload.woNumber != null) {
      url = `api/WorkOrder/GetAllWorkOrders/${locale}?year=${action.payload.searchYear}&workOrderNumber=${action.payload.woNumber}`;
    }
    // if (isTeamLead()) {
    //   var empId = Number(JSON.parse(localStorage.getItem("user"))?.employeeid);
    //   url =
    //     action.payload != null
    //       ? `${url}/teamLeadId=${empId}`
    //       : `${url}?teamLeadId=${empId}`;
    // }

    const response = yield call(() => getRequest(url));
    yield put(onSuccess(response.data));
  } catch (e) {
    yield put(onFailure());
    message.warning("No data");
  }
}

function* getStatusLookup() {
  try {
    let locale =
      localStorage.getItem("selectedlanguage")?.substring(0, 2) ?? "fr";
    const response = yield call(() =>
      getRequest(`api/Lookup/GetAllStatus/${locale}`)
    );
    yield put(onGetAllStatusSuccess(response.data));
  } catch (e) {
    yield put(onGetAllStatusFailure());
    message.warning("Data not available");
  }
}

function* deleteWorkOrder(action) {
  try {
    const response = yield call(() =>
      deleteRequest(`api/WorkOrder/DeleteById/${action.payload}`)
    );
    yield put(onDeleteSuccess(response.data));
    message.success("Deleted successfully");
  } catch (e) {
    yield put(onDeleteFailure());
    message.warning("Delete failed");
  }
}

function* getTeamLeadLookup() {
  try {
    const response = yield call(() => getRequest("api/Lookup/TeamLeads"));
    yield put(onTeamLeadLookupSuccess(response.data));
  } catch (e) {
    yield put(onFailure());
  }
}

function* editWorkOrder(action) {
  try {
    const response = yield call(() =>
      postRequest("api/WorkOrder/Edit", action.payload)
    );
    yield put(onEditSuccess(response.data));
    message.success("Work Order updated successfully");
  } catch (e) {
    yield put(onFailure());
    message.warning("Update failed");
  }
}
function* getWorkOrderLookup() {
  try {
    const response = yield call(() =>
      getRequest(`api/Lookup/WorkOrderLookup`)
    );
    yield put(onWorkOrderLookupSuccess(response.data));
  } catch (e) {
    yield put(onFailure());
    message.warning("Data not available");
  }
}

function* unlinkProductionCalenderForWO(action) {
  try {
    const response = yield call(() =>
      postRequest(`api/WorkOrder/UnlinkProductionCalenderForWorkOrder?productionCalId=${action.payload.ProdcutionCalenderId}&workOrderId=${action.payload.workOrderId}`)
    );
    yield put(onWorkOrderLookupSuccess(response.data));
    yield put(onStart(action.payload));

  } catch (e) {
    yield put(onFailure());
    message.warning("Data not available");
  }
}
export default function* rootSaga() {
  yield all([
    takeLatest(onStart, WorkorderAPI),
    takeLatest(onDeleteStart, deleteWorkOrder),
    takeLatest(onGetAllStatusStart, getStatusLookup),
    takeLatest(onTeamLeadLookupStart, getTeamLeadLookup),
    takeLatest(onEditStart, editWorkOrder),
    takeLatest(onWorkOrderLookupStart, getWorkOrderLookup),
    takeLatest(onUnlinkProdCalForWorkOrderStart, unlinkProductionCalenderForWO),
  ]);
}
