import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  greenHouseLookup: [],
  productionCalenderDetails: [],
  loader: false,
  colorMasterLookup: [],
  containerMasterLookup: [],
  finalProductLookup: [],
  workOrderNextNumber: null,
  teamLeadLookup: [],
};

const ProductionCalendarSlice = createSlice({
  name: "procutionCalender",
  initialState,
  reducers: {
    onGreenHouseStart(state) {
      // state.loader = true;
    },
    onGreenHouseSuccess(state, action) {
      // state.loader = false;
      state.greenHouseLookup = action.payload;
    },
    onGreenHouseFailure(state) {
      // state.loader = false;
    },
    onGetProductionCalenderStart(state) {
      state.loader = true;
    },
    onGetProductionCalenderSuccess(state, action) {
      state.loader = false;
      state.productionCalenderDetails = action.payload;
    },
    onGetProductionCalenderFailure(state, action) {
      state.loader = false;
    },
    onColorMasterLookupStart(state) {
      // state.loader = true;
    },
    onColorMasterLookupSuccess(state, action) {
      // state.loader = false;
      state.colorMasterLookup = action.payload;
    },
    onColorMasterLookupFailure(state) {
      // state.loader = false;
    },
    onContainerMasterLookupStart(state) {
      // state.loader = true;
    },
    onContainerMasterLookupSuccess(state, action) {
      //  state.loader = false;
      state.containerMasterLookup = action.payload;
    },
    onContainerMasterLookupFailuer(state) {
      //  state.loader = false;
    },
    onProdCalSaveStart(state) {
      // state.loader = true;
    },
    onProdCalSaveSuccess(state, action) {
      // state.loader = false;
    },
    onProdCalSaveFailure(state) {
      //state.loader = false;
    },
    onFinalProdLookupStart(state) {
      //state.loader = true;
    },
    onFinalProdLookupSuccess(state, action) {
      // state.loader = false;
      state.finalProductLookup = action.payload;
    },
    onDeleteProdCalenStart(state) {
      state.loader = true;
    },
    onDeleteProdCalenSuccess(state) {
      state.loader = false;
    },
    onCreateWorkOrderStart(state) {
      state.loader = true;
    },
    onCreateWorkOrderSuccess(state) {
      state.loader = false;
    },
    onCreateWorkOrderFailure(state) {
      state.loader = false;
    },
    onGetWONextNumStart(state) {
      // state.loader = true;
    },
    onGetWONextNumSuccuss(state, action) {
      /// state.loader = false;
      state.workOrderNextNumber = action.payload;
    },
    onGetWONextNumFailure(state, action) {
      // state.loader = false;
    },
    onTeamLeadLookupStart(state) {
      //state.loader = true;
    },
    onTeamLeadLookupSuccess(state, action) {
      // state.loader = false;
      state.teamLeadLookup = action.payload;
    },
    onFailure(state) {
      // state.loader = false;
    },
    onUploadExcelStart(state) {
      state.loader = true;
    },
    onUploadExcelSuccess(state, action) {
      state.loader = false;
    },
    onUploadExcelFailure(state, action) {
      state.loader = false;
    },
  },
});

export const {
  onGreenHouseStart,
  onGreenHouseSuccess,
  onGreenHouseFailure,
  onGetProductionCalenderStart,
  onGetProductionCalenderSuccess,
  onGetProductionCalenderFailure,
  onColorMasterLookupStart,
  onColorMasterLookupSuccess,
  onColorMasterLookupFailure,
  onContainerMasterLookupStart,
  onContainerMasterLookupSuccess,
  onContainerMasterLookupFailuer,
  onProdCalSaveStart,
  onProdCalSaveSuccess,
  onProdCalSaveFailure,
  onFinalProdLookupStart,
  onFinalProdLookupSuccess,
  onDeleteProdCalenStart,
  onDeleteProdCalenSuccess,
  onCreateWorkOrderStart,
  onCreateWorkOrderSuccess,
  onGetWONextNumStart,
  onGetWONextNumSuccuss,
  onGetWONextNumFailure,
  onTeamLeadLookupStart,
  onTeamLeadLookupSuccess,
  onFailure,
  onUploadExcelStart,
  onUploadExcelSuccess,
  onUploadExcelFailure,
} = ProductionCalendarSlice.actions;

export default ProductionCalendarSlice.reducer;
