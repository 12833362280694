import authentication from "features/OnBoarding/authenticationSlice";
import productionCalendar from "features/ProductionCalendar/productionCalenderSlice";
import productionMaster from "features/ProductionMaster/ProductionMasterSlice";
import workOrder from "features/WorkOrder/WorkOrderSlice";
import dailyUpdate from "features/DailyUpdate/DailyUpdateSlice";
import dashboard from "features/Dashboard/DashboardSlice";
import reports from "features/Reports/ReportsSlice";
import finalProduct from "features/FinalProduct/FinalProductSlice";
import thermometer from "features/Thermometre/ThermometreSlice";
import inventory from "features/Inventory/InventorySlice";

//Include all the reducer to combine and provide to configure store.

const rootReducer = {
  authentication,
  productionCalendar,
  productionMaster,
  workOrder,
  dailyUpdate,
  dashboard,
  reports,
  finalProduct,
  thermometer,
  inventory,
};

export default rootReducer;
