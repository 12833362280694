import { createSlice } from "@reduxjs/toolkit";
import jwtDecode from "jwt-decode";
const initialState = {
  workOrderToUpdate: {},
  activityLookup: [],
  loader: false,
};

const dailyUpdateSlice = createSlice({
  name: "dailyUpdate",
  initialState,
  reducers: {
    getWorkOrder(state) {
      state.loader = true;
    },
    getWorkOrderSuccess(state, action) {
      state.loader = false;
      state.workOrderToUpdate = action.payload;
    },

    getWorkOrderFailure(state) {
      state.loader = false;
    },
    saveDailyUpdate(state) {
      state.loader = true;
    },
    hideLoader(state) {
      state.loader = false;
    },
    getActivityCodeLookup(state) {
      state.loader = true;
    },
    getActivityCodeLookupSuccess(state, action) {
      state.loader = false;
      state.activityLookup = action.payload;
    },
  },
});

export const {
  getWorkOrder,
  getWorkOrderSuccess,
  getWorkOrderFailure,
  saveDailyUpdate,
  hideLoader,
  getActivityCodeLookup,
  getActivityCodeLookupSuccess,
} = dailyUpdateSlice.actions;
export default dailyUpdateSlice.reducer;
