import { all } from "redux-saga/effects";
import authenticateSaga from "features/OnBoarding/authenticateAPI";
import productionMasterSaga from "features/ProductionMaster/ProductionMasterAPI";
import workOrderSaga from "features/WorkOrder/WorkOrderAPI";
import dailyUpdateSaga from "features/DailyUpdate/DailyUpdateAPI";
import productionCalendarSega from "features/ProductionCalendar/productionCalenderAPI";
import dashboardSaga from "features/Dashboard/DashboardAPI";
import reportsSaga from "features/Reports/ReportsAPI";
import finalProductSaga from "features/FinalProduct/FinalProductAPI";
import thermometreSaga from "features/Thermometre/ThermometreAPI";
import inventorySaga from "features/Inventory/InventoryAPI";

// Here you can include all the saga which you write for components
export default function* rootSaga() {
  yield all([
    authenticateSaga(),
    productionCalendarSega(),
    productionMasterSaga(),
    workOrderSaga(),
    dailyUpdateSaga(),
    dashboardSaga(),
    reportsSaga(),
    finalProductSaga(),
    thermometreSaga(),
    inventorySaga(),
  ]);
}
