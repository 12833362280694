import { lazy } from "react";

const routes = [
  {
    path: "thermometre",
    component: lazy(() => import("features/Thermometre/Thermometre")),
    exact: true,
  },
  {
    path: "dashboard",
    component: lazy(() => import("features/Dashboard/Dashboard")),
    exact: true,
  },
  {
    path: "productionmaster",
    component: lazy(() => import("features/ProductionMaster/ProductionMaster")),
    exact: true,
  },
  {
    path: "productioncalendar",
    component: lazy(() =>
      import("features/ProductionCalendar/ProductionCalendar")
    ),
    exact: true,
  },
  {
    path: "workorder",
    component: lazy(() => import("features/WorkOrder/WorkOrder")),
    exact: true,
  },
  {
    path: "adddailyupdates/:id",
    component: lazy(() => import("features/DailyUpdate/DailyUpdate")),
    exact: true,
  },
  {
    path: "adddailyupdates",
    component: lazy(() => import("features/DailyUpdate/DailyUpdate")),
    exact: true,
  },
  {
    path: "reports",
    component: lazy(() => import("features/Reports/Reports")),
    exact: true,
  },
  {
    path: "finalProduct",
    component: lazy(() => import("features/FinalProduct/FinalProduct")),
    exact: true,
  },
  {
    path: "inventory",
    component: lazy(() => import("features/Inventory/Inventory")),
    exact: true,
  },
  // {
  //   path: 'users',
  //   component: lazy(() => import('features/Users')),
  //   exact: true,
  // },
];

export default routes;
