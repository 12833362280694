import React, { Suspense, useState, useContext } from "react";
import { FormattedMessage } from "react-intl";
import { Route, Switch, Link } from "react-router-dom";
import routes from "./routes";
import { Layout, Menu, Button, Avatar, Space, Row, Col, Select } from "antd";
import {
  ShoppingCartOutlined,
  MenuOutlined,
  ControlOutlined,
  BarChartOutlined,
  CalendarOutlined,
  CopyOutlined,
  PaperClipOutlined,
  CheckSquareOutlined,
  ShopOutlined,
} from "@ant-design/icons";
import logo from "../logo.png";
import logo1 from "../logo1.png";
import { Context } from "../components/Wrapper";
import { isAdmin } from "../features/OnBoarding/Authorizaton";
const { Header, Sider, Footer, Content } = Layout;

function ProtectedRoutes() {
  const [collapsed, setCollapsed] = useState(true);

  const context = useContext(Context);
  return (
    <Layout>
      <Layout>
        <Sider
          trigger={null}
          collapsible
          collapsed={collapsed}
          breakpoint="lg"
          defaultCollapsed={true}
          collapsedWidth="60"
        >
          <img
            src={logo}
            className="img-fluid after-login-logo normal-logo"
            alt=""
          />
          <img
            src={logo1}
            className="img-fluid after-login-logo collapsed-logo"
            alt=""
          />
          <Menu theme="light" mode="inline" defaultSelectedKeys={["1"]}>
            <Menu.Item key="0" icon={<ControlOutlined />}>
              <FormattedMessage id="Thermometre.Heading" />
              <Link to="/thermometre" />
            </Menu.Item>
            <Menu.Item key="1" icon={<BarChartOutlined />}>
              <FormattedMessage id="Dashboard.Heading" />
              <Link to="/dashboard" />
            </Menu.Item>
            <Menu.Item key="8" icon={<CheckSquareOutlined />}>
              <FormattedMessage id="Dashboard.Inventory" />
              <Link to="/inventory" />
            </Menu.Item>
            <Menu.Item key="6" icon={<ShopOutlined />}>
              <FormattedMessage id="ProductionCalendar.ProductFinal" />
              <Link to="/finalProduct" />
            </Menu.Item>
            {isAdmin() && (
              <>
                {/* <Menu.Item key="2" icon={<ShoppingCartOutlined />}>
                  <FormattedMessage id="ProductionMaster.Heading" />
                  <Link to="/productionmaster" />
                </Menu.Item> */}
                <Menu.Item key="3" icon={<CalendarOutlined />}>
                  <FormattedMessage id="ProductionCalendar.Heading" />
                  <Link to="/productioncalendar" />
                </Menu.Item>
              </>
            )}
            <Menu.Item key="4" icon={<CopyOutlined />}>
              <FormattedMessage id="WorkOrder.Heading" />
              <Link to="/workorder" />
            </Menu.Item>
            <Menu.Item key="5" icon={<PaperClipOutlined />}>
              <FormattedMessage id="Reports.Heading" />
              <Link to="/reports" />
            </Menu.Item>
          </Menu>
        </Sider>
        <Layout
          className="site-layout"
          style={{
            overflow: "auto",
            minHeight: "100vh",
            width: "100%",
            paddingBottom: "56px",
            left: 0,
          }}
        >
          <Header
            className="site-layout-background"
            style={{
              padding: "0 20px",
              borderBottom: "solid 1px #eee",
            }}
          >
            <Row>
              <Col flex="auto">
                {React.createElement(collapsed ? MenuOutlined : MenuOutlined, {
                  className: "trigger",
                  onClick: () => setCollapsed(!collapsed),
                })}
              </Col>
              <Col flex="100px" className="langselect">
                <Select
                  value={context.locale}
                  onChange={context.selectLang}
                  bordered={false}
                >
                  <option value="en-US">English</option>
                  <option value="fr-FR">French</option>
                  <option value="es-ES">Spanish</option>
                </Select>
              </Col>
              <Col flex="100px">
                <Space align="end">
                  <Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />
                  &nbsp;
                  {
                    <a
                      onClick={() => {
                        localStorage.clear();
                        window.location.reload(true);
                      }}
                    >
                      Logout
                    </a>
                  }
                </Space>
              </Col>
            </Row>
          </Header>
          <Content
            className="site-layout-background"
            style={{
              margin: "0",
              padding: 0,
              minHeight: 280,
              textAlign: "justify",
            }}
          >
            <Switch>
              <Suspense fallback={<div>Loading...</div>}>
                {routes.map(({ component: Component, path, exact }, index) => (
                  <Route path={`/${path}`} key={index} exact={exact}>
                    <Component />
                  </Route>
                ))}
              </Suspense>
            </Switch>
          </Content>
          <Footer
            style={{
              borderTop: "1px solid #e8e8e8",
              position: "fixed",
              left: 0,
              bottom: 0,
              width: "100%",
              textAlign: "center",
            }}
          >
            © 2021 Cleroux. All Rights Reserved.
          </Footer>
        </Layout>
      </Layout>
    </Layout>
  );
}

export default ProtectedRoutes;
