import { all, call, put, takeLatest } from "redux-saga/effects";
import { onStart, onSuccess, onFailure } from "./ReportsSlice";
import { getRequest } from "app/axiosClient";
import { message } from "antd";

function* getReports() {
  try {
    let locale = localStorage.getItem("selectedlanguage")?.substring(0, 2) ?? "fr";
    const response = yield call(() => getRequest(`api/Reports/GetReportsData?language=${locale}`));
    yield put(onSuccess(response.data));
  } catch (e) {
    yield put(onFailure());
    message.warning("Data not available");
  }
}

function* getFilteredReports(action) {
  try {
    let locale = localStorage.getItem("selectedlanguage")?.substring(0, 2) ?? "fr";
    const response = yield call(() => getRequest(`api/Reports/GetReportsData?language=${locale}&date=${action.payload.date}&activity=${action.payload.activity}&site=${action.payload.site}&upc=${action.payload.upc}&code=${action.payload.code}&workOrder=${action.payload.workOrderNum}&greenHouse=${action.payload.greenHouse}`));
    yield put(onSuccess(response.data));
  } catch (e) {
    yield put(onFailure());
    message.warning("Data not available");
  }
}

export default function* rootSaga() {
  yield all([takeLatest(onStart, getReports), takeLatest("FILTERED_REPORTS_REQUESTED", getFilteredReports)]);
}
