import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

const initialState = {
  inventoryList: [],
  loader: false,
};

const InventorySlice = createSlice({
  name: "inventory",
  initialState,
  reducers: {
    onInventoryDataLoadStart(state) {
      state.loader = true;
    },
    onSuccess(state) {
      state.loader = false;
    },
    onFailure(state) {
      state.loader = false;
    },
    onUploadExcelStart(state) {
      state.loader = true;
    },
    onInventoryDataLoaded(state, action) {
      state.loader = false;
      state.inventoryList = action.payload;
    },
    onUploadExcelSuccess(state, action) {
      state.loader = false;
    },
    onUploadExcelFailure(state, action) {
      state.loader = false;
    },
  },
});

export const {
  onInventoryDataLoadStart,
  onSuccess,
  onFailure,
  onInventoryDataLoaded,
  onUploadExcelStart,
  onUploadExcelSuccess,
  onUploadExcelFailure,
} = InventorySlice.actions;

export default InventorySlice.reducer;
